import * as React from 'react'

import { Feature } from '@thg-commerce/enterprise-network/src/generated/graphql'

import { EnterpriseContext } from '../../EnterpriseContext'

export interface RenderFeatureProps {
  features: string[]
  renderCondition?: (availableFeatures: string[]) => boolean
  renderAlternative?: React.ReactNode
  children?: React.ReactNode
}

export const RenderFeature = (props: RenderFeatureProps) => {
  const { horizonFeatures, config } = React.useContext(EnterpriseContext)

  const enabledFeatures = React.useMemo(
    () =>
      props.renderCondition &&
      props.features.filter(
        (queryFeature) =>
          horizonFeatures?.includes(queryFeature as Feature) ||
          (config && !!config.siteConfig[queryFeature]),
      ),
    [
      horizonFeatures,
      props.features,
      props.renderCondition,
      config?.siteConfig,
    ],
  )

  const conditionResult =
    horizonFeatures && config && enabledFeatures && props.renderCondition
      ? props.renderCondition(enabledFeatures)
      : props.features.every(
          (feature: string) =>
            horizonFeatures?.includes(feature as Feature) ||
            !!config?.siteConfig[feature],
        ) ?? false

  return conditionResult ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : props.renderAlternative ? (
    <React.Fragment>{props.renderAlternative}</React.Fragment>
  ) : (
    <React.Fragment />
  )
}

export const PassFeaturesAsProps = ({
  children,
  featureMap,
}: {
  children: React.ReactNode
  // tslint:disable-next-line:prefer-array-literal
  featureMap: Object
}) => {
  const { horizonFeatures, config } = React.useContext(EnterpriseContext)

  const enabledFeatures = React.useMemo(
    () =>
      Object.keys(featureMap).filter(
        (queryFeature) =>
          horizonFeatures?.includes(queryFeature as Feature) ||
          (config && !!config.siteConfig[queryFeature]),
      ),
    [featureMap, horizonFeatures, config?.siteConfig],
  )

  const childrenWithProps = (enabledFeautres) =>
    React.Children.map(children, (child) => {
      const c = child as React.ReactElement
      const props = {}

      Object.keys(featureMap).forEach((value) => {
        if (enabledFeautres.includes(value)) {
          props[featureMap[value]] = true
        }
      })

      return React.cloneElement(c, {
        ...props,
        ...c.props,
      })
    })

  return <React.Fragment>{childrenWithProps(enabledFeatures)}</React.Fragment>
}
